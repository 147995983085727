// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-view-item-js": () => import("./../../../src/pages/viewItem.js" /* webpackChunkName: "component---src-pages-view-item-js" */),
  "component---src-templates-paginated-page-template-js": () => import("./../../../src/templates/paginatedPageTemplate.js" /* webpackChunkName: "component---src-templates-paginated-page-template-js" */),
  "component---src-templates-single-js": () => import("./../../../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */)
}

